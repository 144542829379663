.select-box--container {
    height: 37px;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .select-box--box {
    min-width: 160px;
    width: 155px;
    position: absolute;
    left: -45px;
  }
  
  .select-box--selected-item {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 4px 12px;
    vertical-align: middle;
  }
  
  .select-box--items div {
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 6px;
    padding-left: 20px;
  }
  .select-box--items {
    background: rgb(255, 255, 255);
    margin-top: 4px;
    width: 155px;
    box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 20%), 0 6.7px 5.3px rgb(0 0 0 / 5%), 0 12.5px 10px rgb(0 0 0 / 0%), 0 22.3px 17.9px rgb(0 0 0 / 1%), 0 41.8px 33.4px rgb(0 0 0 / 1%), 0 100px 80px rgb(0 0 0 / 0%);
  }
  .select-box--items div:hover {
    background: #ccc;
    cursor: pointer;
   }
  .select-box--arrow {
    width: 39px;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0px;
  }
  
  .select-box--arrow-down {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #6d6767;
  }
  .select-box--arrow-up {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #6d6767;
  }
  
  .filter-open {
    background: #6c757d;
  }
  .filter-open-text {
    color: #fff;
  }
