/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.notification-container .notification-error {
  /* background-color: #bd362f; */
  background-color: #fff;
  color: #bd362f;
}

.notification-container {
  overflow-y: inherit !important;
  overflow-x: inherit !important;
}

.c-checkbox-wrap {
  display: flex;
  align-items: center;
}
.c-checkbox-wrap label {
  margin-bottom: 0;
  margin-left: 15px;
  padding: 7px 0;
}

/* .table-bordered td, .table-bordered th {
  border: 1px solid #d70f0f;
} */

.disabled {
  background: #dddddd;
}

.justify-content-center {
  justify-content: center !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.dropdown-content {
  background-color: white;
  overflow: auto;
  z-index: 99;
  position: absolute;
  /* border-radius: .25rem;
    border: 1px solid #ced4da; */
}

.dropdown-content a {
  color: black;
  padding: 3px 15px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid lightgray;
}

.dropdown-content div {
  /* min-height: 220px; */
  max-height: 220px;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.m-t-50 {
  margin-top: 50px;
}

.mt3 {
  margin-top: 3%;
}

.fc th {
  background-color: #2c3e50;
  color: #fff;
  height: 27px;
}

.fc td {
  text-align: center;
}

.fc table {
  border: 1px solid black;
}

/* event colors */
a.hvac-Install {
  background-color: #800020;
  border-color: #800020;
}

a.hvac-ReCall {
  background-color: red;
  border-color: red;
}

a.hvac-WorkPending {
  background-color: yellow;
  border-color: yellow;
}

a.hvac-WorkPending .fc-event-title {
  color: black;
}

a.hvac-1st {
  background-color: green;
  border-color: green;
}

a.hvac-Repair {
  background-color: blue;
  border-color: blue;
}

a.fc-event-past.event,
a.fc-event-past.note,
a.fc-event-end.note {
  background-color: #d86f15 !important;
  border-color: #d86f15 !important;
}
a.fc-event-past.event .fc-daygrid-event-dot,
a.fc-event-past.note .fc-daygrid-event-dot,
a.fc-event-end.note .fc-daygrid-event-dot {
  border-color: #ffffff;
}
a.hvac-WaitingOn.Customer.Approval {
  background-color: #6e3be9 !important;
  border-color: #6e3be9 !important;
}

body.fixed-left .side-menu.left .logo-wrapper .logo img {
  max-width: 120px;
  margin: auto;
  display: block;
}
body.fixed-left .side-menu.left .logo-wrapper {
  background-color: #c6c6c6;
  padding: 10px;
}
body.fixed-left.enlarged .content-page {
  transition: all ease-in-out 0.3;
}
.table-header {
  display: flex;
}
.table-header .right-section {
  margin-left: auto;
}
.table-header .left-section {
  display: flex;
}
.table-header .input-wrap {
  margin-right: 15px;
  max-width: 170px;
}
.table-header .select-wrap {
  margin-left: 15px;
}

/* schedule page css */
.schedule ul {
  list-style-type: none;
}
.schedule .filter-status {
  padding-left: 0;
}
.schedule .filter-section .filter,
.schedule .filter-section .filter-list {
  padding-left: 0;
}
.bg-all-b {
  border-bottom: 3px solid rgb(69, 69, 69) !important;
}
.bg-offered-b {
  border-bottom: 3px solid rgb(90, 204, 37) !important;
}
.bg-unscheduled-b {
  border-bottom: 3px solid rgb(251, 54, 64) !important;
}
.bg-scheduled-b {
  border-bottom: 3px solid rgb(23, 149, 204) !important;
}
.bg-paused-b {
  border-bottom: 3px solid rgb(255, 219, 40) !important;
}
.bg-inprogress-b {
  border-bottom: 3px solid rgb(255, 144, 85) !important;
}
.bg-completed-b {
  border-bottom: 3px solid rgb(202, 212, 221) !important;
}

.bg-all-l {
  border-left: 3px solid rgb(69, 69, 69) !important;
}
.bg-offered-l {
  border-left: 3px solid rgb(90, 204, 37) !important;
}
.bg-unscheduled-l {
  border-left: 3px solid rgb(251, 54, 64) !important;
}
.bg-scheduled-l {
  border-left: 3px solid rgb(23, 149, 204) !important;
}
.bg-paused-l {
  border-left: 3px solid rgb(255, 219, 40) !important;
}
.bg-inprogress-l {
  border-left: 3px solid rgb(255, 144, 85) !important;
}
.bg-completed-l {
  border-left: 3px solid rgb(202, 212, 221) !important;
}

.bg-all {
  background-color: rgb(69, 69, 69) !important;
}
.bg-offered {
  background-color: rgb(90, 204, 37) !important;
}
.bg-unscheduled {
  background-color: rgb(251, 54, 64) !important;
}
.bg-scheduled {
  background-color: rgb(23, 149, 204) !important;
}
.bg-paused {
  background-color: rgb(255, 219, 40) !important;
}
.bg-inprogress {
  background-color: rgb(255, 144, 85) !important;
}
.bg-completed {
  background-color: rgb(202, 212, 221) !important;
}

.fg-all {
  color: rgb(69, 69, 69) !important;
}
.fg-offered {
  color: rgb(90, 204, 37) !important;
}
.fg-unscheduled {
  color: rgb(251, 54, 64) !important;
}
.fg-scheduled {
  color: rgb(23, 149, 204) !important;
}
.fg-paused {
  color: rgb(255, 219, 40) !important;
}
.fg-inprogress {
  color: rgb(255, 144, 85) !important;
}
.fg-completed {
  color: rgb(202, 212, 221) !important;
}

.schedule .filter-status li {
  list-style-type: none;
  padding: 12px;
  cursor: pointer;
  height: 39px;
  line-height: 10px;
}

.schedule .filter-status li.active {
  color: #fff;
}
.cp {
  cursor: pointer !important;
}
.select-box-estimation .select-box--box {
  max-width: inherit;
  position: relative;
}
.table-header.table-header-estimation .input-wrap {
  max-width: 170px;
  position: relative;
}
.select-box-estimation .select-box--box {
  min-width: inherit;
  position: static;
}
.filterdate .select-box--box {
  min-width: 150px;
}
.filterby .select-box--box {
  min-width: 140px;
}
.filterby .select-box--arrow {
  right: 7px;
}
.btn-close img {
  max-width: 9px;
  width: 100%;
}
.btn-close {
  position: absolute;
  right: 5px;
  top: 9px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 100%;
  border: 1px solid #cccccc;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workorder-header h5 {
  color: #ffffff;
}

.workorder-tbl .nav-item a {
  background-color: #f1f1f1 !important;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.workorder-tbl .nav-item a.active {
  background-color: #1795cc !important;
}
.workorder-tbl .nav-item {
  margin-bottom: 3px;
  position: relative;
}
.workorder-tbl .nav-item a::after {
  content: '';
  border: solid #909090;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: absolute;
  right: 10px;
  top: 15px;
}
.workorder-tbl .nav-item a.active::after {
  border-color: #ffffff;
}

.top-actions-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.top-actions-right {
  display: flex;
}
.top-actions-right .workorder-icons {
  padding: 10px;
  margin: 0 0 0 25px;
  width: 90px;
  height: auto;
}

.top-actions-right .workorder-icons p {
  margin: 0;
}

.time-picker-input1 p,
.time-picker-input2 p {
  font-size: 13px;
  margin-bottom: 0;
  padding-left: 10px;
  font-weight: 600;
}
.wo-technician-block {
  width: 100%;
}
.wo-technician-block > div:nth-child(1) {
  width: 100%;
}
.mdi-close {
  cursor: pointer;
}

.attchement-img-preview .span-container {
  display: inline-block;
  border: 1px solid #c5c5c5;
  padding: 2px;
  border-radius: 4px;
  overflow: hidden;
  margin: 5px;
  position: relative;
}
.attchement-img-preview .span-container .mdi-close {
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 4px;
  width: 15px;
  height: 15px;
  background-color: #f00;
  font-size: 12px;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ae-checkbox {
  height: 16px !important;
  cursor: pointer;
}

body.fixed-left.enlarged
  .page-content-wrapper.schedule
  .card-body
  .btn[style='left: 0px;'],
body.fixed-left
  .page-content-wrapper.schedule
  .card-body
  .btn[style='left: 0px;'] {
  left: 0px !important;
}
body.fixed-left.enlarged .page-content-wrapper.schedule .card-body .btn {
  left: -15px !important;
  top: 0;
}
body.fixed-left .page-content-wrapper.schedule .card-body .btn {
  left: -15px !important;
  top: 0;
}
body .pagination li.true,
body .pagination li:hover {
  background: #2c3e50;
  border-color: #2c3e50;
}
body .pagination li a {
  color: #2c3e50;
}
body .pagination li.true a,
body .pagination li:hover a {
  color: #ffffff;
}
#sidebar-menu {
  background-color: #c6c6c6;
}
#sidebar-menu ul ul a.active-menu {
  background: rgba(0, 0, 0, 0.05);
}
.select-box--container {
  position: relative;
}

.dv-star-rating .dv-star-rating-star {
  margin-bottom: 0px !important;
}
.table-header-estimation
  .right-section
  .select-box-estimation
  .col
  .select-box--box,
.table-header-estimation .right-section .select-box-estimation .col input,
.table-header-estimation
  .right-section
  .select-box-estimation
  .col
  button.btn.btn-primary {
  width: 100%;
}
.custom-rating-styling .card#review-page .card-body > .form-group {
  display: flex;
  align-items: flex-end;
}

.top-actions-right.icons-hidden .workorder-icons {
  opacity: 0;
  pointer-events: none;
}
.new-header-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
body.fixed-left .menu-left .button-menu-mobile img.menu-non-expanded {
  display: none;
}
body.fixed-left .menu-left .button-menu-mobile img.menu-expanded {
  display: inline-block;
  max-width: 22px;
  margin-top: -8px;
}

body.fixed-left.enlarged .menu-left .button-menu-mobile img.menu-non-expanded {
  display: inline-block;
  max-width: 22px;
  margin-top: -8px;
}
body.fixed-left.enlarged .menu-left .button-menu-mobile img.menu-expanded {
  display: none;
}

@media only screen and (max-width: 1440px) {
  .table-header-estimation .right-section .select-box-estimation .col {
    max-width: 150px;
  }
  .table-header-estimation
    .right-section
    .select-box-estimation
    .col
    .filterdate
    .select-box--box {
    max-width: 100%;
    white-space: nowrap;
    min-width: inherit;
  }
  .table-header-estimation
    .right-section
    .select-box-estimation
    .col
    .filterdate
    .select-box--box
    .select-box--container {
    padding-right: 25px;
  }
  .table-header-estimation
    .right-section
    .select-box-estimation
    .col
    .filterdate
    .select-box--box
    .select-box--container
    .select-box--selected-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1024px) {
  body.fixed-left .side-menu.left {
    padding: 70px 0 15px;
  }
  body.fixed-left .side-menu.left .logo-wrapper {
    background-color: #fff;
    padding: 25px 15px;
  }
  body.fixed-left .side-menu.left .logo-wrapper .logo {
    display: block;
  }
  body.fixed-left.enlarged .content-page {
    margin-left: 240px;
  }
}

.p-r-10 {
  padding-right: 10px;
}
@media only screen and (max-width: 767px) {
  .table-header .left-section,
  .table-header .right-section,
  .table-header {
    width: 100%;
    flex-wrap: wrap;
  }
  .table-header .right-actions {
    margin-bottom: 15px;
    float: right;
  }
}

.poexpense {
  max-width: 100%;
  overflow-y: scroll;
  max-height: 500px;
}

.dataTables_wrapper table thead tr th {
  max-width: 200px;
}

/* .overflowX{
  overflow-y: scroll;
} */
.dataTables_scrollHead .table-responsive {
  overflow: hidden;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-overlay.full-loader {
  z-index: 9999999;
}

.time-picker-input .react-datepicker-wrapper input {
  width: 128% !important;
}

.strwrap {
  width: 10em; /* the element needs a fixed width (in px, em, %, etc) */
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis;
}

.centeralign {
  text-align: center;
}

.fullwidth {
  width: 100%;
}

.minheight {
  max-height: 200px !important;
  overflow-y: scroll;
  max-width: 448px;
  table-layout: fixed;
}

.mt5 {
  margin-top: 5px;
}

.mt7 {
  margin-top: 3%;
}

.checkbox {
  height: 28px;
  margin-left: -26%;
}

.l-m-10 {
  margin-left: 10px;
}

.imageUpload {
  color: rgba(0, 0, 0, 0);
}

.cardHeight {
  height: 335px;
}

.hide {
  display: none;
}

.estimate-icons {
  font-size: 12px;
  white-space: nowrap;
}
.prev .react-tel-input input {
  width: 100% !important;
}
.prev label {
  display: inline !important;
}

.icon-b {
  padding-left: 25px;
  padding-right: 10px;
  text-align: center;
}
.saveBtns {
  width: 91px;
}
.avgClass {
  visibility: hidden;
}
#avgTime1:hover :nth-child(odd) {
  visibility: visible;
}
#avgTime2:hover :nth-child(odd) {
  visibility: visible;
}
#avgTime3:hover :nth-child(odd) {
  visibility: visible;
}
#avgTime4:hover :nth-child(odd) {
  visibility: visible;
}
#collapseOne1,
#collapseOne2 {
  max-height: 295px;
  overflow-y: scroll;
}
#collapseOne1 label {
  cursor: pointer;
}
#collapseOne2 label {
  cursor: pointer;
}
.resetFilter {
  cursor: pointer;
}
.f-btns {
  margin-left: 10px;
}
.filter-date {
  width: 240px;
  margin-left: 40px;
  margin-bottom: 5px;
}
.selected-daterange {
  position: absolute;
  top: 13px;
  left: 116px;
}
.selected-statuses {
  position: absolute;
  top: 13px;
  left: 353px;
}
.wrapper-timeline {
  width: 100%;
  margin-top: 0px;
  padding-top: 20px;
  position: absolute;
}
.customer-details
  .form-group.row
  .col-sm-4
  .col-sm-6.row
  input.form-control.checkbox {
  margin-top: 10px;
  height: 20px;
  width: 20px;
  margin-right: -6px;
}
.customer-details
  .form-group.row
  .col-sm-4
  .col-sm-6.row
  label.col-form-label[for='example-checkbox-input1'],
.customer-details
  .form-group.row
  .col-sm-4
  .col-sm-6.row
  label.col-form-label[for='example-checkbox-input2'] {
  margin-top: 4px;
}

::-webkit-scrollbar-track-piece:start {
  /* Select the top half (or left half) or scrollbar track individually */
}

::-webkit-scrollbar-thumb:window-inactive {
  /* Select the thumb when the browser window isn't in focus */
}

::-webkit-scrollbar-button:horizontal:decrement:hover {
  /* Select the down or left scroll button when it's being hovered by the mouse */
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6c757d;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6c757d;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #6c757d;
}

input[type='checkbox'],
input[type='radio'] {
  min-width: 15px;
  margin-top: 2px;
}

@media screen and (max-width: 1440px) {
  label {
    font-size: 12px !important;
  }
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  #avgTime1.card-body.text-center.py-5,
  #avgTime2.card-body.text-center.py-5,
  #avgTime3.card-body.text-center.py-5 {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }
}

#toolTip {
  background-color: #2a2330e6;
  border: 1px solid #3a3142e6;
  width: 100%;
  height: 75%;
  /* width: 200px;
  height: 180px; */
  top: -15px;
  margin-left: 2px;
  position: relative;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  box-shadow: 0px 0px 8px -1px black;
  -moz-box-shadow: 0px 0px 8px -1px black;
  -webkit-box-shadow: 0px 0px 8px -1px black;
}

#toolTip span {
  padding-left: 10px;
  color: #fff;
}

#tailShadow {
  width: 3px;
  height: 3px;
  position: absolute;
  top: 16px;
  left: -8px;
  z-index: -10;
  box-shadow: 0px 0px 8px 1px black;
  -moz-box-shadow: 0px 0px 8px 1px black;
  -webkit-box-shadow: 0px 0px 8px 1px black;
}

#tail1 {
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #362f3be6 transparent transparent;
  position: absolute;
  left: -21px;
  top: 2px;
}

/* #tail2 {
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #37313de6 transparent transparent;
  position: absolute;
  left: -21px;
  top: 2px;
} */

.rating-popup .form-group label,
.Feedback {
  color: #bebebe;
  font-size: 17px !important;
  display: block;
  margin-bottom: 0px;
}

.rating-popup .modal-body .dv-star-rating {
  width: 33%;
  /* margin-left: 102px; */
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  justify-content: space-evenly;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.review-card:hover {
  background: #f5f3f3;
}
.review-card:hover .review-edit {
  display: block;
}

.review-edit {
  position: absolute;
  display: none;
  right: 20px;
  bottom: 33px;
}

#review-page .card-body .form-group .dv-star-rating label i {
  font-size: 30px;
}

#review-page .card-body .form-group .dv-star-rating {
  margin-left: 90px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #e2dcdc;
}
.nav-tabs .nav-link,
.nav-pills .nav-link {
  border: 1px solid #f6f0f0;
}
.rdw-editor-main {
  height: 230px !important;
}
.map-wrapper {
  min-height: 300px !important;
}
.event-active {
  border: 1px solid #080808;
}

.schedule-filter-popup td {
  vertical-align: baseline;
}
.mdbtable .dataTables_scrollHeadInner {
  padding-right: 5px !important;
}
.event-date .filter-date {
  width: 100%;
  margin-left: 0px;
}
.workorder-icons {
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 72px;
}
.workorder-icons:hover {
  background-color: #f5f5f5;
}

.mdbtable .col-sm-12 {
  padding-right: 0px;
}
.fc-event-title {
  white-space: normal;
}
.note,
.event {
  background-color: #d8d815;
}
.disabled-date {
  pointer-events: none;
}
.search-table table tbody tr {
  cursor: pointer;
}
.small-size {
  font-size: small !important;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
.selector:hover {
  background-color: gray;
}
.nobull {
  list-style-type: none;
}
.ul-list {
  padding: 0;
  margin: auto;
  cursor: pointer;
}
.list-togglebtn {
  /* left: 250px; */
  top: 1px;
  font-size: 15px !important;
  box-shadow: 0 0 2px;
  z-index: 99;
  padding: 5px 4px;
  border: 1px solid #e6e1e1;
}
.tech-para h5 {
  margin-bottom: 0 !important;
}
.tech-cardBody {
  overflow-y: scroll;
  max-height: 460px;
}
.not-cursor {
  cursor: not-allowed !important;
}
.qa-inputs {
  width: 93%;
  margin-left: 15px;
}
.qa-card {
  padding: 30px;
}
.select-sub {
  padding-left: 20px;
}

.dashboard-calendar .fc-day-future,
.fc-day-today {
  cursor: pointer;
}

.travel-calendar .wrapper-timeline .fc-view-harness-active {
  display: none;
}
.travel-calendar .wrapper-timeline {
  width: 96%;
}

.tbl-span2 td {
  border: 1px solid rgb(222 226 230);
  padding: 2px 15px;
}
.nav.nav.nav-tabs.chat {
  min-width: 315px;
  height: 71px;
}
.show > .dropdown-menu.chatBox {
  visibility: visible;
  opacity: 1;
  margin-top: 8% !important;
  padding: 0px 0;
}
/* Inbox-widget */
.inbox-widget .inbox-item {
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
  width: 288px;
}

.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}

.inbox-widget .inbox-item img {
  width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
  color: #333333;
  display: block;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-text {
  color: #a0a0a0;
  display: block;
  font-size: 12px;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-date {
  color: #a9a9a9;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}

.widget-chart-sm svg {
  float: left;
}

.report-card-height {
  height: 97%;
}
.fc .fc-scrollgrid-section table {
  height: 1px;
  width: 100% !important;
}
.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
  width: 100% !important;
}
.margin-start {
  margin-inline-start: 3%;
}

#chatwrapper {
  left: 18%;
  z-index: 999999 !important;
}

body.fixed-left.enlarged #chatwrapper {
    left: 24px;
}

#chat-header-rect {
  height: 10px !important;
}
span.badge.badge-danger.nav-link-badge {
    position: absolute;
    top: 15px;
    right: 5px;
    padding: 0.2rem 0.2rem;
    min-width: 1rem;
    font-size: 11px;
    border-radius: 50%;
}
.custom-table {
  max-height: 500px;
  border-top: 1px solid #dee2e6;
}
.bulk-option {
  position: relative;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 9999;
  padding-bottom: 10px;
}
input#chkAll {
    top: -18px !important;
}
.chat-date {
  display: flex !important;
  justify-content: flex-end !important; 
  font-size: 10px;
}
.active-media {
    background-color: #e8e8e8;
    padding: 5px;
    font-weight: 600;
}
.word {
    width: 200px;
    overflow: hidden;
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-line
}
.bell{
  height: 50px;
}
.word-header{
    width: 150px;
    overflow: hidden;
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-line 
}
.toggle-word-header{
    width: 150px;
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
}
.menubody{
  max-height: 350px !important;
  overflow-y: scroll;
}

.chatBox .nav-tabs .nav-link, .nav-pills .nav-link{
  font-size: unset !important;
}

.css-kj6f9i-menu{
  z-index: 999999 !important;
}

.table-bulk, .select-wrap{
  width: 200px;
}

.select-wrap-pre {
    width: 130px;
    margin-left: -20%;
}

.dv-star-rating i {
    font-size: 30px !important;
}

.date-picker {
   z-index: 99999 !important;
}

.sweetalert div{
  z-index: 9999999 !important;
}

.Toastify__toast-container--top-right {
  top: 3em;
  right: 1em;
  z-index: 99999999;
}

.fcm-notify-body{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewPadding{
  padding: 1.25rem;
  padding-top: 0%;
}

.swal-overlay{
  z-index: 10510000 !important;
}

.paysheet-table .custom-table{
  max-height: none !important;
}

.profilepic{
  border-radius: 50%;
  cursor: pointer;
}

.profile-pen{
  position: absolute;
  top: -3%;
  display: none;
  cursor: pointer;
}

.profile-file:hover~.profile-pen{
  display: block;
  cursor: pointer;
}

.profile-file{
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 44%;
  opacity: 0;
  z-index: 999;
}

.profile-file:hover~.profilepic{
  opacity: 0.5;
}